@import '../../../bps/components/cartPage/cartPage.scss';

.cart-page {
  $root: &;

  .grid {
    .row:first-child > div {
      padding-top: 30px;

      @media (min-width: $screen-tablet-portrait) {
        padding-top: 40px;
      }
    }
  }

  &__bottom {
    @include fullWidth();
  }
}
