.basket-package-unit {
  border-radius: 4px;
  margin-bottom: 6px;
  background: $whiteColor;
  font-size: 14px;
  border: .5px solid $warningColor;
  transition: border .3s;

  &--full {
    border-color: $successColor;
  }

  @media (min-width: $screen-tablet-portrait) {
    font-size: 16px;
  }

  &__head {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    min-height: 62px;
    cursor: pointer;
  }

  &__statement {
    flex: 1 1;
    margin: 8px 0;

    em {
      font-style: normal;
      font-weight: $fontWeightBold;
      color: $warningColor;
    }

    &--full {
      em {
        color: $successColor;
      }
    }
  }

  &__icon-container {
    padding: 2px;
    width: 78px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: $screen-mobile-landscape) {
      width: 86px;
    }
  }

  &__toggle {
    appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    padding: 16px;

    @include iconFont(arrowDown) {
      font-size: 25px;
      color: $selectIconColor;
      transition: transform .3s;
    }

    &--open::before {
      transform: scale(1, -1);
    }
  }

  &__content {
    padding: 0 16px 16px;
    transition-duration: .3s;

    @media (min-width: $screen-tablet-sm) {
      padding-left: 86px;
    }

    ul {
      list-style: none;
      padding: 0 0 0 24px;
      margin: 0;
      position: relative;
    }

    li {
      line-height: 1.3;
      margin: 0 0 12px;

      @include iconFont(checked) {
        position: absolute;
        left: 0;
        transform: translate(2px, 2px);
        color: $successColor;
        vertical-align: middle;
      }
    }
  }
}
