.miles-and-more-widget {
  $root: &;
  display: flex;
  align-items: flex-end;
  flex-flow: row wrap;
  background: $whiteColor;
  border: 1px solid $grayLightColor;
  border-radius: 4px;
  padding: 8px;
  margin: 16px 0;

  @media (min-width: $screen-tablet-landscape) {
    align-items: flex-start;
  }

  &__select-head {
    font-size: 16px;
    margin: 6px 20px 6px 6px;
    padding: 0;
    line-height: 1.2;
    position: relative;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    border: 0 none;
    background: transparent;

    @include iconFont(arrowDown, after) {
      color: $textBaseColor;
      position: absolute;
      right: -20px;
      top: 50%;
      transform: translateY(-50%) scale(1, 1);
      transition: transform 300ms;
      font-size: 25px;
    }

    img {
      height: 20px;
      margin-right: 12px;
    }

    #{$root}--open &:after {
      transform: translateY(-50%) scale(1, -1);
    }

    &:focus-visible { // stylelint-disable-line
      outline-offset: 15px;
    }
  }

  &__container {
    width: 100%;
    padding: 16px 0 0;
  }

  &__reset-button {
    display: block;
    text-decoration: underline;
    margin: 24px auto;
    cursor: pointer;
    text-align: center;
  }

  &__button {
    width: 100%;
    margin: 8px 0;
  }

  &__input {
    margin: 8px 0;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none; // stylelint-disable-line  plugin/no-unsupported-browser-features
      margin: 0;
    }

    input[type="number"] {
      appearance: textfield; // stylelint-disable-line  plugin/no-unsupported-browser-features
    }
  }

  &__checkbox {
    margin: 12px 0;
  }

  &__input-notice {
    font-size: $fontSizeSmall;
    margin: -2px 0 8px;
  }

  &__label {
    margin: 8px 0;

    & + & {
      margin-top: 24px;
    }
  }

  &__value {
    margin: 8px 0 24px;
    font-size: $fontSizeBase;
  }

  &__info {
    margin: 8px 0 16px;

    &--success {
      font-weight: $fontWeightSemiBold;
      font-size: $fontSizeBase;
      margin: 8px 0 24px;

      @include iconFont('check') {
        color: $successColor;
        margin: 0 8px 0 0;
        transform: scale(1.2);
      }

      & + & {
        margin: -8px 0 24px;
      }
    }
  }

  &__account-number {
    display: block;
    background: $milesAndMoreColor;
    color: $whiteColor;
    border-radius: 4px;
    text-align: center;
    padding: 8px;
    margin: 0 0 24px;
    line-height: 1.2;
  }

  &__disclaimer {
    padding-top: 16px;
    margin-top: 24px;
    border-top: $grayLightColor 1px solid;
  }

  &__info-popup {
    font-weight: $fontWeightRegular;
    top: -2px;
  }

  &__error {
    margin: 0 0 16px;
  }
}
