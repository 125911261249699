.checkout-step-confirmation {
  flex-direction: column;

  &__content {
    @include container($maxWidthInner);
    padding-top: 24px;
    padding-bottom: 24px;

    @media (min-width: $screen-tablet-portrait) {
      @include container();
      padding-top: 40px;
      padding-bottom: 0;
    }

    &--no-padding-top {
      padding-top: 0;
    }

    &--wide {
      width: 100%;
      padding: 0 !important;
      margin-left: -15px;
      margin-right: -15px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  &__footer {
    width: 100%;
    padding-top: 30px;

    @media (min-width: $screen-tablet-portrait) {
      padding-top: 40px;
    }
  }

  &__header {
    @include container($maxWidthInner);
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    margin: 15px 0 0;

    @media (min-width: $screen-tablet-portrait) {
      @include container();
      margin: 40px auto 0;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
  }

  &__headline {
    font-size: 25px;
    margin: 0;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 41px;
    }
  }

  &--list-background {
    background-color: transparent;
  }

  &__content-row {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $screen-tablet-portrait) {
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;
    }

    &--lg {
      @media (min-width: $screen-tablet-portrait) {
        flex-direction: row;
      }
    }

    &--trusted {
      @media (min-width: $screen-tablet-portrait) {
        flex-flow: row wrap;
      }

      @media (min-width: $screen-tablet-landscape) {
        flex-wrap: nowrap;
      }
    }

    &--voucher-download {
      display: block;
      background: $backgroundWhiteColor;
      padding: 16px;
      margin: 16px 0;
    }
  }

  &__content-image {
    overflow: hidden;

    .lazy-load-image {
      position: relative;
      height: 100%;
    }

    @media (min-width: $screen-tablet-portrait) {
      width: 50%;
      display: flex;
      justify-content: flex-start;
    }

    img {
      float: left;
      width: 100%;

      @media (min-width: $screen-tablet-portrait) and (max-width: $screen-tablet-portrait-max) {
        width: auto;
        height: 175px;
      }

      @media (min-width: $screen-tablet-landscape) {
        width: auto;
        height: 100%;
        min-height: 225px;
        max-height: 300px;
      }
    }
  }

  &__content-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 16px;
    padding: 16px;
    width: 100%;
    background: $whiteColor;

    @media (min-width: $screen-tablet-portrait) {
      width: 50%;
      margin: 0 0 0 24px;
      flex-grow: 1;
      padding: 20px 25px;
    }
  }

  &__content-column {
    width: 100%;
    margin: 0;
    padding: 20px 0 0;
    font-size: 14px;
    line-height: 1.4;
    display: flex;
    flex-wrap: wrap;
    border-top: 0 none;
    justify-content: flex-start;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0;
      margin: 20px 0 0;
      flex-direction: row;
    }

    @media (min-width: $screen-tablet-landscape) {
      width: 50%;
    }

    &--first {
      padding: 0;
      border-top: 0 none;

      @media (min-width: $screen-tablet-portrait) {
        border-top: 0 none;
        margin: 20px 0 0;
      }
    }

    &-full {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      min-height: 100px;
      width: 100%;
    }

    &-inner {
      border-bottom: 1px solid $textDarkestColor;

      @media (min-width: $screen-tablet-portrait) {
        border-bottom: 0 none;
      }

      .checkout-step-confirmation__content-column-full {
        margin: 0 0 20px;

        @media (min-width: $screen-tablet-portrait) {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
        }
      }
    }

    &-button {
      flex-grow: 1;
      align-self: flex-start;
      width: 100%;

      @media (min-width: $screen-tablet-portrait) {
        width: auto;
      }

      .button {
        width: 100%;

        @media (min-width: $screen-tablet-portrait) {
          width: auto;
        }
      }
    }

    &--right {
      justify-content: center;
      width: 100%;

      @media (min-width: $screen-tablet-landscape) {
        margin: 20px 0 0 20px;
        width: 50%;
      }
    }
  }

  &__column-headline {
    font-size: 24px;
    font-weight: $fontWeightSemiBold;
    margin: 10px 0;
  }

  &__content-headline {
    font-size: 24px;
    line-height: 1.6;
    color: $textDarkestColor;
    font-weight: $fontWeightMedium;

    @media (min-width: $screen-desktop) {
      font-size: 26px;
    }
  }

  &__content-text {
    font-size: 18px;
    font-weight: $fontWeightMedium;
    line-height: 1;
    color: $textSuccessColor;
    margin: 0 0 8px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 20px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 26px;
      margin: 0 0 16px;
    }
  }

  &__icon {
    display: flex;
    margin: 10px 20px 10px 0;
    justify-content: center;
    flex-direction: column;
  }

  &__icon-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
  }

  &__text {
    font-size: 18px;
    font-weight: $fontWeightRegular;
    margin: 10px 0 25px;
  }

  &__voucher-btn {
    margin: 16px 0;
  }

  &__voucher-list {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__download-btn {
    font-size: 14px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 16px;
    }

    @include iconFont(download) {
      font-size: 26px;
      transform: translate(-4px, 6px);
      margin: 0 0 0 -4px;
    }
  }

  &__voucher-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid $grayLightColor;
    padding: 15px 0 0;
    margin: 0 0 15px;

    @media (min-width: $screen-tablet-portrait) {
      flex-direction: row;
    }

    &:first-child {
      border-top: 0 none;
    }

    .price-info__current {
      justify-content: center;
    }
  }

  &__voucher-item-price {
    min-height: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (min-width: $screen-tablet-portrait) {
      min-height: auto;
      min-width: 150px;
    }
  }

  .sovendus {
    margin-top: 40px;
  }
}
